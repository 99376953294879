import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;

  img {
      width: 200px;
  }

  @media (max-width: 900px) and (orientation: landscape) {
    flex: 0 1 25px;
  }
`
const Logo = () => {
    return (
      <LogoWrap as={Link} to="/">
          <img src="https://onlinecassinos.org/images/cassinosonline.webp" width="200" height="45" alt="Cassinos Online" title="Online Cassinos"/>
      </LogoWrap>
    )
}

export default Logo