import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  white-space: nowrap;
  margin: 0 10px;
  transition: all 100ms ease-in;
  position: relative;
  line-height: initial;
  text-transform: none;
  font-size: 15px;
  @media (min-width: 901px) {
    :after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0%;
      content: ".";
      color: transparent;
      background: rgb(5, 60, 75);
      height: 1px;
      transition: all 0.2s ease-in;
    }
    :hover {
      color: #acafb2;
      ::after {
        width: 100%;
      }
    }
  }
  @media (max-width: 900px) {
    padding: 10px 0;
    font-size: 1.1rem;
    z-index: 6;
    font-weight: lighter;
    width: 50%;
    text-align: center;
    :hover {
      color: #acafb2;
    }
  }
`
const Hr =styled.hr`
display: none;

@media (max-width:900px) {
  display: block;
  width: 50%;
  border-width: 0.5px;
}

`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/" itemprop="Lista">Online Cassinos</NavItem><Hr/>
    </>
  )
}

export default NavbarLinks
